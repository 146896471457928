<template>
  <div>
    <v-form ref="form2" v-model="valid" lazy-validation>
      <v-dialog v-model="showForm" max-width="700px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }} </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.code"
                    label="Code"
                    :rules="[(v) => !!v || 'Code obligatoire']"
                    :readonly="readonly"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.ref"
                    label="Reference"
                    :readonly="readonly"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <datepicker
                    label="Date d'acquisition"
                    v-model="editedItem.date_acc"
                    :edit="!readonly"
                    :date_max="$store.state.today"
                    :clearable="false"
                    :key="cs"
                  ></datepicker>
                </v-col>
                <v-col cols="12" sm="2" md="12">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.label"
                    label="Libelle"
                    :rules="[(v) => !!v || 'Libelle obligatoire']"
                    :readonly="readonly"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.description"
                    label="Description"
                    :readonly="readonly"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.valeur_acc"
                    :readonly="readonly"
                    label="Valeur d'aquisition"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.vitessemax"
                    :readonly="readonly"
                    label="Vitesse Max"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="editedItem.struct_id"
                    :items="structures_list"
                    item-text="label"
                    item-value="id"
                    :rules="[(v) => !!v || 'Atelier obligatoire']"
                    label="Atelier"
                    dense
                    :readonly="readonly"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.label"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="item.Hierarchy"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="save"
              v-if="!readonly"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click="close"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_MACHINE from "../graphql/Production/CREATE_MACHINE.gql";
import UPDATE_MACHINE from "../graphql/Production/UPDATE_MACHINE.gql";

export default {
  name: "machineform",
  components: {
    datepicker: () => import("./DatePicker.vue"),
  },
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    readonly: Boolean,
    structures: Array,
  },

  data: () => ({
    menu: false,
    valid: true,
    editedItem: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    parent: [],
    color: "",
    progress: false,
    kus: 2000,
    cs: 100,
  }),

  computed: {
    structures_list() {
      let list = [];
      if (this.structures)
        list = this.structures.filter(
          (elm) => elm.type_id == "3" && elm.ChildCount == 0
        );
      return list;
    },
    formTitle() {
      return this.editedItem.id == -1 ? "Ajouter Machine" : "Modifier Machine";
    },
  },

  watch: {},

  created() {},
  mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      this.cs++;
      if (this.editedItem.id < 0 && this.structures_list.length == 1) {
        this.editedItem.struct_id = this.structures_list[0].id;
      }
      this.kus++;
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    close() {
      this.$emit("close", this.editedItem);
    },

    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async save() {
      if (this.$refs.form2.validate()) {
        if (this.editedItem.id > 0) {
          let v = {
            machine: {
              id: this.editedItem.id,
              code: this.editedItem.code,
              ref: this.editedItem.ref,
              label: this.editedItem.label,
              vitessemax: this.editedItem.vitessemax,
              date_acc: this.editedItem.date_acc,
              description: this.editedItem.description,
              valeur_acc: this.editedItem.valeur_acc,
              struct_id: this.editedItem.struct_id,
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(UPDATE_MACHINE, v);
          if (r) {
            this.items.splice(this.editedItem.index, 1, this.editedItem);
            this.$emit("update", this.editedItem);
            this.close();
          }
        } else {
          let v = {
            machine: {
              code: this.editedItem.code,
              ref: this.editedItem.ref,
              label: this.editedItem.label,
              vitessemax: this.editedItem.vitessemax,
              date_acc: this.editedItem.date_acc,
              description: this.editedItem.description,
              valeur_acc: this.editedItem.valeur_acc,
              struct_id: this.editedItem.struct_id,
              create_uid: this.$store.state.me.id,
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(CREATE_MACHINE, v);
          if (r) {
            this.editedItem.id = r.createMachine.id;
            this.items.push(this.editedItem);
            this.close();
          }
        }
      }
    },
  },
};
</script>

<style></style>
